





































































// this module is used to create a new user and then either:
// - direct them to the invite's sponsor org
// - direct them to create a new org as a client of the invite sponsor
import Vue from 'vue'
import api from '@/api';
import User, { DefaultUser } from '@/models/User';
import InviteAbstract from '@/models/InviteAbstract';
import UserSession from '@/models/UserSession';
// components
import MaterialInput from '@/components/MaterialInput.vue';
import PasswordRequirements from '@/components/account/PasswordRequirements.vue';
import ImageUploader from '@/components/ImageUploader.vue';
import TnCModal from '@/components/modals/TnCModal.vue';
//validation
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';


extend('required', {
    ...required,
    message: '{_field_} is required'
});

extend('email', {
    ...email,
    message: '{_field_} must be a valid email'
});

interface Data {
    user: User;
    invalidEmail: boolean;
    draggingImageUpload: boolean;
    acceptedTnC: boolean;
}

export default Vue.extend({
    name:'SignUp2.0',
    components: {
        MaterialInput,
        PasswordRequirements,
        ImageUploader,
        ValidationProvider,
        ValidationObserver,
        TnCModal
    },
    props: {
        invite: { type: Object as () => InviteAbstract, required: true }
    },
    data(): Data {
        return {
            user: { ...DefaultUser, username: this.invite.recipient, firstName: this.invite.firstName, lastName: this.invite.lastName },
            invalidEmail: false,
            draggingImageUpload: false,
            acceptedTnC: false
        }
    },
    methods: {
        setProfilePic(uri: string) {
            this.user.profilePic = uri;
        },
        closeTnC() {
            this.$bvModal.hide('tnc-modal');
        },
        acceptTnC() {
            this.$bvModal.hide('tnc-modal');
            this.acceptedTnC = true;
            this.saveNewUser();
        },
        async saveNewUser() {
            try {

                if(!this.acceptedTnC) {
                    this.$bvModal.show('tnc-modal');
                    return;
                }
                
                this.invalidEmail = false;
                const hlink = this.$route.query.id;
                
                this.user.recoveryEmail = this.user.recoveryEmail || this.user.username; // for possible future where we will ask for recovery email up front
                
                // post to 'create' endpoint
                const rslt = await this.$http.post(`${api}/account/create/${hlink}`, this.user, { headers: { 'Accept':'application/json'}});
                const profile: UserSession = rslt.data;

                sessionStorage.setItem('token', profile.token);
                this.$store.commit('setUserSession', profile);

                if(this.invite.isNewClient) {                                   // send user to create org
                    this.$router.push({ path: `/profile/create?id=${hlink}` });
                } else {                                                        // send user to dashboard
                    this.$router.push({ name: 'NavBoard', params: { pid: profile.primaryOrg! }})
                }

            } catch (error: any) {
                if(error.status == 403) {
                    this.invalidEmail = true;
                }
                // eslint-disable-next-line
                console.error(error);
            }
        }
    }
})
