



































import { NullableString } from '@/models/Types';
import api from '@/api';
import Vue from 'vue';

interface Data {
    dragging: Boolean
    imageFile: NullableString
}

export default Vue.extend({
    name: 'ImageUploader',
    props: {
        container: { type: String, required: false },
        outer: { type: Object, required: false },
        inner: { type: String, required: false },
        value: { type: String, required: false }
    },
    data(): Data {
        return {
            dragging: false,
            imageFile: null
        }
    },
    methods: {
        draggingStart() {
            this.$emit('draggingStart')
        },
        draggingStop() {
            this.$emit('draggingStop')
        },
        browseForFile() {
            (this.$refs.file_upload as HTMLInputElement).click();
        },
        async dropFile(e: DragEvent) {
            this.dragging = false
            if (e.dataTransfer !== null && e.dataTransfer.files.length > 0) {
                const file = e.dataTransfer.files.item(0)!
                const formData = new FormData()
                formData.append("file", file, file.name)
                await this.uploadFile(formData);
            }
        },
        async fileChanged(files: any) {
            if (files.length > 0) {
                const formData = new FormData();
                formData.append("file", files[0], files[0].name)
                await this.uploadFile(formData);
            }
        },
        async uploadFile(form: FormData){
            try {
                const rslt = await this.$http.post(`${api}/upload/image`, form, { headers: {"Content-Type": "multipart/form-data"}});
                this.imageFile = rslt.data;
                this.$emit('onUpload', this.imageFile);
            } catch (error) {
                // eslint-disable-next-line
                console.error(error)
            }
        }
    },
    mounted() {
        this.imageFile = this.value;
    }
})
