var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TnCModal',{on:{"onHide":_vm.closeTnC,"onAccept":_vm.acceptTnC}}),_c('ValidationObserver',{attrs:{"name":"signup-validator"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"row mb-1"},[_c('div',{staticClass:"col-md-6 pr-md-1"},[_c('div',{staticClass:"form-group wb-form-group"},[_c('ValidationProvider',{attrs:{"name":"First Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('MaterialInput',{attrs:{"id":"firstname","label":"First Name","note":"Required","errors":errors,"tabindex":"4"},model:{value:(_vm.user.firstName),callback:function ($$v) {_vm.$set(_vm.user, "firstName", $$v)},expression:"user.firstName"}})]}}],null,true)})],1)]),_c('div',{staticClass:"col-md-6 pl-md-1"},[_c('div',{staticClass:"form-group wb-form-group"},[_c('ValidationProvider',{attrs:{"name":"Last Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('MaterialInput',{attrs:{"id":"lastname","label":"Last Name","note":"Required","errors":errors,"tabindex":"5"},model:{value:(_vm.user.lastName),callback:function ($$v) {_vm.$set(_vm.user, "lastName", $$v)},expression:"user.lastName"}})]}}],null,true)})],1)])]),_c('div',{staticClass:"row mb-1"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-group wb-form-group"},[_c('ValidationProvider',{attrs:{"name":"User Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('MaterialInput',{attrs:{"id":"user_email","label":"Email Address","note":"Required","errors":errors,"tabindex":"1"},model:{value:(_vm.user.username),callback:function ($$v) {_vm.$set(_vm.user, "username", $$v)},expression:"user.username"}})]}}],null,true)})],1)])]),_c('div',{staticClass:"row mb-1"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-group wb-form-group"},[_c('ValidationProvider',{attrs:{"vid":"Password","name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('MaterialInput',{attrs:{"id":"password","type":"password","label":"Password","note":"Required","errors":errors,"tabindex":"2"},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})]}}],null,true)}),_c('PasswordRequirements',{model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})],1)])]),_c('div',{staticClass:"row mb-1"},[_c('div',{staticClass:"col col-md-5"},[_c('ImageUploader',{attrs:{"container":"file-upload","outer":{'file-upload-wrapper': true, dragging: _vm.draggingImageUpload},"inner":"file-upload-inside"},on:{"draggingStart":function($event){_vm.draggingImageUpload = true},"draggingStop":function($event){_vm.draggingImageUpload = false},"onUpload":_vm.setProfilePic},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h3',[_vm._v("Profile Photo")])]},proxy:true},{key:"instructions",fn:function(){return [_c('span',[_vm._v("PNG, GIF, JPG or SVG format, max 512KB")])]},proxy:true}],null,true)})],1)]),_c('div',{staticClass:"row mb-1"},[(_vm.invalidEmail)?_c('div',{staticClass:"col text-left"},[_c('h4',{staticClass:"error"},[_vm._v(" This invitation is not valid for this email address. ")])]):_c('div',[_c('p')])]),_c('div',{staticClass:"row mb-1 form-btns text-right"},[_c('button',{staticClass:"cancel text-blue"},[_vm._v("Cancel")]),_c('button',{staticClass:"btn btn-primary btn-raised",attrs:{"disabled":invalid},on:{"click":function($event){$event.preventDefault();return _vm.saveNewUser.apply(null, arguments)}}},[_vm._v("Create Your Account")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }