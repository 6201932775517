
































































	import Vue from 'vue';
	import api from '@/api';
	import InviteAbstract from '@/models/InviteAbstract';
	//components
	import SignUp from '@/components/account/SignUp.2.0.vue';
	import AcceptInvite from '@/components/account/AcceptInvite.vue';
	import BusySpinner from '@/components/modals/BusySpinner.vue';

	interface Data {
		link: {
			value: string,
			isValid: boolean | null
		},
		invite: InviteAbstract | undefined
		isBusy: boolean
	}

    export default Vue.extend({
		name: 'Register',
		components: {
			BusySpinner,
			SignUp,
			AcceptInvite
		},
		data(): Data {
			return {
				link: { value: '', isValid: null },
				invite: undefined,
				isBusy: false
			}
		},
		async mounted() {
			try {
				this.isBusy = true;	

				// validate the link
				const hlink = this.$route.query.id;
				const rslt = await this.$http.get(`${api}/invite/validate/INVITE?id=${hlink}`);
				this.invite = rslt.data as InviteAbstract;

				this.link.value = hlink as string;
				this.isBusy = false;
				this.link.isValid = true;

			} catch (error) {
				this.isBusy = false;
				this.link.isValid = false;
				// eslint-disable-next-line
				console.error(error);
			}
		}
    });
