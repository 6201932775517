import { NullableString } from './Types';

export default interface User {
    username: string;
    password: string;
    firstName: NullableString;
    lastName: NullableString;
    recoveryEmail: NullableString;
    profilePic: NullableString;
}

export const DefaultUser: User = {
    username: '',
    password: '',
    firstName: null,
    lastName: null,
    recoveryEmail: null,
    profilePic: null
}