











import Vue from 'vue';

export default Vue.extend({
    name: 'CompletionIndicator',
    props: {
        label: { type: String, required: true },
        value: { type: Boolean, required: false, default: null }
    },
    computed: {
        isComplete(): boolean {
            return this.value === true;
        }
    }
})
