













    // this module redirects a known user to either:
    // - create a new client org             - or - 
    // = associate with an existing org
    import Vue from 'vue';
    import api from '@/api';
    import InviteAbstract from '@/models/InviteAbstract';
    import UserSession from '@/models/UserSession';

    export default Vue.extend({
        name: 'AcceptInvite',
        props: {
            invite: { type: Object as () => InviteAbstract, required: true }
        },
        methods: {
            async linkUser() {
                
                try {
					const hlink = this.$route.query.id;

                    // post to 'associate' endpoint
                    const rslt = await this.$http.put(`${api}/account/associate/${hlink}`, { headers: { 'Accept':'application/json'}});
                    const profile: UserSession = rslt.data;
                    
                    sessionStorage.setItem('token', profile.token);
                    this.$store.commit('setUserSession', profile);

                    if(this.invite.isNewClient) {                                   // send user to create org
                        window.location.assign(`#/profile/create?id=${hlink}`);
                    } else {                                                        // send user to dashboard of invite org
                        window.location.assign(`#/boards/${profile.currentOrg}`);
                    }
                    
				} catch (error) {
					// eslint-disable-next-line
					console.error(error);
				}
            }
        }
    })
