













































import Vue from "vue";
import Privacy from "@/components/account/Privacy.vue";
import TnC from "@/components/account/TNC.vue";

interface Data {
  activeTab: number;
  tabs: number;
}

export default Vue.extend({
  name: "TnCModal",
  components: {
    Privacy,
    TnC
  },
  data(): Data {
    return {
      activeTab: 1,
      tabs: 2,
    };
  },
  methods: {
    nextTab() {
      const tncModal = document.getElementById("tnc-modal___BV_modal_body_");
      this.activeTab++;
      if (tncModal) {
        tncModal.scrollTop = 0;
      }
    },
    close() {
      this.activeTab = 1;
      this.$emit("onHide");
    },
    agree() {
      this.$emit("onAccept");
    },
  },
});
