















import Vue from 'vue';
import CompletionIndicator from '@/components/CompletionIndicator.vue';

interface Data {
    max: Number;
}

export default Vue.extend({
    name: 'PasswordRequirements',
    components: {
        CompletionIndicator
    },
    props: {
        value: { type: String, required: true }
    },
    data(): Data {
        return {
            max: 1200,
        }
    },
    computed: {
        hasLength(): boolean {
            return this.value.length > 7;
        },
        hasLower(): boolean {
            return new RegExp('[a-z]').test(this.value);
        },
        hasUpper(): boolean {
            return new RegExp('[A-Z]').test(this.value);
        },
        hasDigit(): boolean {
            return new RegExp('[0-9]').test(this.value);
        },
        hasSpecialChar(): boolean {
            return new RegExp('[\!\@\#\$\%\^\&\*]').test(this.value);
        },
        complexity(): number {
            let count = 0;
            const pttn = ['[a-z]', '[0-9]', '[A-Z]', '[!@#$%^&*]' ];
            let i = 2;
            pttn.forEach((e) => {
                i = i * 2;
                let rex = new RegExp(e, 'g');
                let matches = this.value.match(rex);
                if(matches != null) {
                    count += (matches.length * i) * this.value.length;
                }
            })
            return count;
        },
        color(): string {
            if(this.complexity < (1200 * .65)) {
                return 'danger';
            } else if(this.complexity < (1200 * .75)) {
                return 'warning';
            } else if(this.complexity < (1200 * .9)) {
                return 'info';
            } else {
                return 'success'
            }
        }
    }
})
